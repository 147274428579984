import request from '@http';

//2023/05/12 王江毅 数据大屏 项目占比
export function getApartmentTypeRentCondition() {
    return request({
        method: 'GET',
        url: '/api/data/large/ApartmentTypeRentCondition',
    });
}

//2023/05/12 王江毅 数据大屏 区域占比
export function getDistrictRentCondition() {
    return request({
        method: 'GET',
        url: '/api/data/large/DistrictRentCondition',
    });
}

//2023/05/12 王江毅 数据大屏 出租占比
export function getRentProportion(params) {
    return request({
        method: 'GET',
        url: '/api/data/large/RentProportion',
        params,
    });
}

//2023/05/12 王江毅 数据大屏 资金状况
export function getPaymentBar() {
    return request({
        method: 'GET',
        url: '/api/data/large/PaymentBar',
    });
}

//2023/05/12 王江毅 数据大屏 出租状况
export function getRentBar() {
    return request({
        method: 'GET',
        url: '/api/data/large/RentBar',
    });
}

//2023/05/12 王江毅 数据大屏 出租状况
export function getRentTotal() {
    return request({
        method: 'GET',
        url: '/api/data/large/RentTotal',
    });
}

//2023/05/23 王江毅 数据大屏 出租状况
export function getWxVisitsDataList() {
    return request({
        method: 'GET',
        url: '/api/data/wx/getWxVisitsDataList',
    });
}


// 2023/07/24 张晓瑜 数据大屏 滚动信息
export function getScrollInformation(){
    return request({
        method: 'GET',
        url: '/api/data/contract/signScroll',
    })
}